import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const HISTORICO_VENDAS_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/historico-vendas`;

class HistoricoVendaService {
    getHistoricoVenda(matricula, dataInicial, dataFinal) {
        return axios.get(HISTORICO_VENDAS_API_BASE_URL + RESOURCE_V1 + "/" + matricula + "/" + dataInicial + "/" + dataFinal);
    }
}

export default new HistoricoVendaService();