<template>
	<div class="col-12">
		<div class="card">
			<h5>Histórico de Venda</h5>
			<div>
				<div class="formgroup-inline">
					<div>
						<div class="col-12 md:col-12">
						<Calendar 
							:showIcon="true" 
							:showButtonBar="true"
							dateFormat="dd/mm/yy" 
							v-model="dataInicial" 
						/>
						</div>
					</div>  
					<div>
						<div class="col-12 md:col-12">
						<Calendar 
							:showIcon="true" 
							:showButtonBar="true" 
							v-model="dataFinal" 
							dateFormat="dd/mm/yy"
							:minDate="dataInicial"
						/>
						 
						</div>
					</div>
					<div>
						<div class="col-12 md:col-10">
							<Button label="Consultar" icon="pi pi-search" @click="getHistoricoVenda()"></Button> 
						</div>
					</div>
					
				</div>
			</div>


			<DataTable :value="historicoList" 
					:paginator="true" 
					class="p-datatable-gridlines" 
					style="text-align: center" 
					:rows="10" 
					dataKey="id" 
					:rowHover="true" 
					v-model:filters="filters1" 
					filterDisplay="menu" 
					:loading="loading" 
					:filters="filters1" 
					responsiveLayout="stack" 
					breakpoint="800px" 
					sortField="data"
					:sortOrder="-1"
					:globalFilterFields="['codigoEstabelecimento','data','numeroNotaFechamento','numeroRe','descFamilia','safra','descEstabel','quantidade','valorLiquido','valorTotal']" >
				<template #header>
					<div class="flex justify-content-between flex-column sm:flex-row">
						<Button type="button" icon="pi pi-filter-slash" label="Limpar" class="p-button-outlined mb-2" @click="clearFilter1()"/>
						<span class="p-input-icon-left mb-2">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="Procurar" style="width: 100%"/>
						</span>
					</div>
				</template>
				<template #empty>
					Nenhum registro encontrado.
				</template>
				<template #loading>
					Carregando dados. Por favor, aguarde!
				</template>
				<Column field="data" header="Data" style="text-align: left; min-width:2rem" sortable />
				<Column field="numeroNotaFechamento" header="Estabelecimento" style="text-align: center; min-width:2rem" sortable>
					<template #body="{data}">
						{{data.codigoEstabelecimento}} - {{ data.descEstabel }}
					</template>
				</Column>
				<Column field="numeroNotaFechamento" header="Núm. Nota" style="text-align: center; min-width:2rem" sortable>
					<template >
						{{numeroNotaFechamento}}
					</template>
				</Column>
				<Column field="numeroRe" header="Núm. Recibo Entrega" style="text-align: center; min-width:2rem" sortable>
					<template >
						{{numeroRe}}
					</template>
				</Column>
				<Column field="safra" header="Safra" style="text-align: center; min-width:2rem" sortable>
					<template >
						{{safra}}
					</template>
				</Column>
				<Column field="codigoItem" header="Produto" style="text-align: center; min-width:2rem" sortable>
					<template #body="{data}">
						{{ data.descFamilia }}
					</template>
				</Column>
				<Column field="quantidade" header="Qtd (kg)" style="text-align: right; min-width:2rem" sortable>
					<template #body="{data}">
						<span class="text-bold">{{formatDecimal(data.quantidade)}}</span>
					</template>
				</Column>
				<Column field="valorTotal" header="Valor total (R$)" style="text-align: right; min-width:2rem" sortable>
					<template  #body="{data}">
						<span class="text-bold">{{formatCurrency(data.valorTotal)}}</span>
					</template>
				</Column>	
				<Column field="valorLiquido" header="Valor líquido (R$)" style="text-align: right; min-width:2rem" sortable>
					<template  #body="{data}">
						<span class="text-bold">{{formatCurrency(data.valorLiquido)}}</span>
					</template>
				</Column>
			</DataTable>
		</div>
	</div>
</template>

<script>
    import Formatacao from '../../utilities/Formatacao';
	import {FilterMatchMode} from 'primevue/api';
    import HistoricoVendaService from "../../service/HistoricoVendaService.js"

	export default {
        props: {
			matricula: {
				type: Number,
				required: true
			}
		},

		data() {
			return {
				filters1: null,
				loading: false,
				idFrozen: false,
				expandedRows: [],
                dataInicial: Formatacao.subtrairMeses(new Date(), 3),
                dataFinal: new Date(),
                historicoList: []
			}
		},

		created() {
			this.initFilters1();
            this.getHistoricoVenda();
		},

		methods: {
			initFilters1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},

            getHistoricoVenda() {
				this.carregarLoading();
                HistoricoVendaService.getHistoricoVenda(
                    this.$props.matricula,
                    Formatacao.formatDateCustom(this.dataInicial, 'DD-MM-YYYY'),
                    Formatacao.formatDateCustom(this.dataFinal, 'DD-MM-YYYY')
                ).then((response) => {
                    this.historicoList = response.data;

					this.historicoList.map(h => {
						h.data = this.formatDate(h.data)
					})
                }).finally(() => {
					this.carregarLoading();
				});
            },

			clearFilter1() {
				this.initFilters1();
			},

			formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatCurrency(value, minimumFractionDigits, maximumFractionDigits);
			},

			formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
				return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
			},

			formatDate(data) {                            
            	return Formatacao.formatDate(data);
			},

			carregarLoading() {
				this.loading = !this.loading;
			},
		}
	}
</script>


<style lang="scss" scoped>   
    @import '../../assets/css/master.scss';    
	@import '../../assets/demo/styles/badges.scss';
</style>