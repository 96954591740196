<template>
    <HistoricoVendas :matricula="currentUser.matricula"/>
</template>

<script lang="js">

    import HistoricoVendas from '../../components/consulta/HistoricoVendas.vue'

    export default {
        data(){
            return {
                
            }
        },
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},
        components: { HistoricoVendas }
    }

</script>